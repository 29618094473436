import DynanmicVideoInView from '@components/common/DynamicVideo/DynamicVideoInView'
import { Button } from '@components/ui'
import cn from 'classnames'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { FC, ReactNode } from 'react'
import tailwindConfig from 'tailwind.config.js'
import resolveConfig from 'tailwindcss/resolveConfig'
import s from './CollectionGrid.module.css'
const fullConfig = resolveConfig(tailwindConfig as any)

interface props {
  items: {
    background_image: {
      dimensions: {
        width: number
        height: number
      }
      url: string
      alt: string
    }
    video_desktop: {
      url: string
    }
    video_mobile: {
      url: string
    }
    text_colour_light: boolean
    button_link: string
    button_style: string
    button_text: string
    collection_title: {
      text: string
    }[]
    title_style: string
    icon: {
      dimensions: {
        width: number
        height: number
      }
      url: string
    }
  }[]
  primary: {
    background_colour: string
    padding_top: boolean
    section_subheading: string
    section_heading: string
  }
}

const CollectionGrid: FC<props> = ({ primary, items }) => {
  const { background_colour, padding_top, section_subheading, section_heading } = primary
  const { screens } = fullConfig.theme as Theme
  const breakPointNum = parseInt(screens['tablet'].split('px').join(''))
  return (
    <section
      className={`pb-40 laptop:pb-80${padding_top ? ' pt-40 laptop:pt-80' : ''}`}
      style={{ backgroundColor: background_colour }}
    >
      <div className="wrapper">
        {section_heading && <div className="title mb-8 text-moss">{section_heading}</div>}
        {section_subheading && <div className="heading-5">{section_subheading}</div>}
        <div
          className={`grid grid-cols-8 laptop:grid-cols-12 gap-12 laptop:gap-16${
            section_heading || section_subheading ? ' mt-32' : ''
          }`}
        >
          {items &&
            items.map((item, i) => {
              const titleStyle = item.title_style ? ` ${item.title_style.toLowerCase()}` : ''
              return (
                <GridItem key={i} url={item?.button_link}>
                  {item.background_image.url && (
                    <div className="collection__bg relative">
                      <Image
                        src={item.background_image.url}
                        layout="fill"
                        objectFit="cover"
                        alt={item.background_image.alt || 'Sheet Society'}
                        sizes={'(min-width: 1024px) 680px, (min-width: 768px) 959px, 375px'}
                      />
                    </div>
                  )}
                  {item.video_desktop.url && (
                    <DynanmicVideoInView
                      mediaDesktop={item.video_desktop}
                      mediaMobile={item.video_mobile.url != '' ? item.video_mobile : item.video_desktop}
                      breakPoint={breakPointNum}
                      settings={{ playsInline: true, loop: true, muted: true }}
                      style="absolute top-0"
                    />
                  )}

                  <div className="absolute top-0 h-full w-full flex flex-col justify-center items-center">
                    {item.collection_title[0]?.text && (
                      <div className={cn(s.title, `${item.text_colour_light ? 'text-white' : 'text-black'}`)}>
                        {item.collection_title.map((title, i) => (
                          <p key={`${title}-${i}`} className={`text-center${titleStyle}`}>
                            {title.text}
                          </p>
                        ))}
                      </div>
                    )}
                    {Object.keys(item.icon).length > 0 && (
                      <div className={cn(s.icon, 'max-w-200 mt-14')}>
                        <Image
                          src={item.icon?.url}
                          width={item.icon.dimensions?.width}
                          height={item.icon.dimensions?.height}
                          alt={item.collection_title[0]?.text}
                        />
                      </div>
                    )}
                  </div>

                  {item.button_text && (
                    <Button
                      Component="div"
                      variant={`${item.button_style.toLowerCase()}`}
                      bg={item.text_colour_light ? 'dark' : 'light'}
                      className={cn(s.btn, 'absolute bottom-32 left-1/2 transform -translate-x-1/2 bg-transparent')}
                    >
                      {item?.button_text}
                    </Button>
                  )}
                </GridItem>
              )
            })}
        </div>
      </div>
      <style jsx global>{`
        .collection__bg {
          padding-top: 100%;
          width: 100%;
        }
      `}</style>
    </section>
  )
}

interface GridItemProps {
  url: string
  children?: ReactNode
}
const GridItem: FC<GridItemProps> = ({ url, children }) => {
  if (url) {
    return (
      <Link href={url || '/'} passHref legacyBehavior>
        <a className={`collection col-span-8 laptop:col-span-6 relative`}>{children}</a>
      </Link>
    )
  } else {
    return <div className={`collection col-span-8 laptop:col-span-6 relative`}>{children}</div>
  }
}
export default CollectionGrid
